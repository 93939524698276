import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import { API } from "aws-amplify"
import {
  clientVendorsByClient,
  updateVENDOR,
  createVENDOR,
  createINSURANCEAGENT,
  createCLIENTVENDORS,
  updateStarredVendor,
  createCATEGORYVENDORS,
  deleteCATEGORYVENDORS,
  createPROJECTVENDORS,
  deletePROJECTVENDORS,
  updateVendorStatus,
  updateVendorNotificationStatus,
  createVENDORCOVERAGE,
  updateVENDORCOVERAGE,
  updateINSURANCEAGENT,
  deleteVENDORCOVERAGE,
  deleteCERTIFICATE,
  getCLIENTVENDORS,
  updateCERTIFICATE,
  createCERTIFICATE,
  updateCERTIFICATECOVERAGE,
  createCERTIFICATECOVERAGE,
  deleteCERTIFICATECOVERAGE,
  getCERTIFICATE,
  createEMAILNOTIFICATIONS,
  createACORD25SCAN,
  updateACORD25SCAN,
  certificateByClientVendor,
  deleteVENDOR,
  deleteCLIENTVENDORS
} from "graphql/vendor"
import {
  deleteCertificateFile,
  deleteCertificateFromS3,
  uploadCertificateFile,
  copyScannedAcord25,
  uploadCertificateSupplementalCoiFile,
  deleteCertificateSupplementalCoiFile,
} from "services/appResources"
import {certificateByClient} from "../graphql/client";
import { Api } from "@mui/icons-material";
import { usToAWSDate } from "utils/dateUtils";

const adapter = createEntityAdapter()
const initialState = adapter.getInitialState({
  status: "idle",
  vendors: null,
  certificates: {}, // cache each certificate by id for quick access

  // certs for a single vendor
  vendorCertificates: null,
  paginatedVendorCertificates: null,
  vendorCertificatesCount: 0,
})

function getFirstExp (coverages) {
  if(coverages?.length) {
    return coverages.map(coverage => coverage.policyExp)
      .flat().filter(exp => exp)
      .sort((a,b) => {
        if( new Date(a) > new Date(b) ) {
          return -1
        } else {
          return 1
        }
      })
      .pop() || null
  }
  return null
}

async function createVendorCategories(categoriesToAdd, clientVendorID) {
  await Promise.all(categoriesToAdd.map(async(categoryToAdd, index) => {
    categoryToAdd && await API.graphql({
      query: createCATEGORYVENDORS,
      variables: {
        input: {
          categoryID: categoryToAdd,
          clientVendorID,
          primary: index === 0
        }
      }
    })
    return Promise.resolve()
  }))
}

async function deleteVendorCategories(categoriesToDelete) {
  if(categoriesToDelete?.length) {
    await Promise.all(categoriesToDelete.map(async (categoryToDelete) => {
      await API.graphql({
        query: deleteCATEGORYVENDORS,
        variables: {
          input: {
            id: categoryToDelete,
          }
        }
      })
      return Promise.resolve()
    }))
  }
}

async function createVendorProjects(projectsToAdd, clientVendorID) {
  if(projectsToAdd?.length) {
    await Promise.all(projectsToAdd.map(async(projectToAdd, index) => {
      projectToAdd && await API.graphql({
        query: createPROJECTVENDORS,
        variables: {
          input: {
            projectID: projectToAdd,
            clientVendorID,
          }
        }
      })
      return Promise.resolve()
    }))
  }
}

async function deleteVendorProjects(projectsToDelete) {
  if(projectsToDelete?.length) {
    const response = await Promise.all(projectsToDelete.map(async (projectToDelete) => {
      const deleteOneresponse = await API.graphql({
        query: deletePROJECTVENDORS,
        variables: {
          input: {
            id: projectToDelete,
          }
        }
      })
      Promise.resolve()
      return {
       success:  !!deleteOneresponse.data.deletePROJECTVENDORS,
       id: projectToDelete
      }
    }))
    return response;
  }
}

async function updateVendorCategories(vendorData) {
  const { clientVendorID } = vendorData
  const existingCategories = vendorData?.categories?.items
  let primaryCategory = vendorData?.primaryCategory
  const otherCategories = new Set(vendorData?.otherCategories)
  const categoriesToDelete = []
  const categoriesDeleted = []

  if(existingCategories?.length) {
    existingCategories.forEach(existingCategory => {
      if(existingCategory.primary) {
        if(existingCategory.categoryID !== primaryCategory) {
          // if the new primary category is different, delete the existing one
          categoriesToDelete.push(existingCategory.id) // contains the CategoryVendor ID
          categoriesDeleted.push(existingCategory.categoryID) // contains the actual categoryID
        } else {
          // if the new primary category is same, set it to false so it doesn't get added again
          primaryCategory = false
        }
      } else if(!otherCategories.has(existingCategory.categoryID)) {
        // if this category is NOT in the list of other categories, delete it
        categoriesToDelete.push(existingCategory.id) // contains the CategoryVendor ID
        categoriesDeleted.push(existingCategory.categoryID) // contains the actual Category's ID
      } else {
        // if this category is in the list of other categories, remove it from the add list
        otherCategories.delete(existingCategory.categoryID)
      }
    })
  }

  const categoriesToAdd = [
    primaryCategory, // the first category to add is always the primary
    ...Array.from(otherCategories)
  ]

  await createVendorCategories(categoriesToAdd, clientVendorID)
  await deleteVendorCategories(categoriesToDelete)
  return {
    categoriesAdded: categoriesToAdd,
    categoriesDeleted
  }
}

async function updateVendorProjects(vendorData) {
  const { clientVendorID, defaultProject } = vendorData
  const selectedProjects = new Set(vendorData.selectedProjects)
  const existingProjects = vendorData?.projects?.items
  let existingDefaultProject = false
  let hasOtherProjects = false
  const projectsToDelete = []
  const projectsDeleted = []
  if(existingProjects?.length) {
    existingProjects.forEach(existingProject => {
      if(selectedProjects.has(existingProject.projectID)) {
        selectedProjects.delete(existingProject.projectID)

        if(existingProject.projectID === defaultProject?.id) {
          selectedProjects.delete(existingProject.projectID)
          existingDefaultProject = existingProject
        } else {
          hasOtherProjects = true
        }

      } else {
        projectsToDelete.push(existingProject.id) // contains the ProjectVendor ID
        projectsDeleted.push(existingProject.projectID) // contains the actual Project's ID
      }
    })
  }

  if(!existingDefaultProject && (!hasOtherProjects && !selectedProjects.size)) {
    if(defaultProject) {
      selectedProjects.add(defaultProject.id)
    }
  } else if(existingDefaultProject && (hasOtherProjects || selectedProjects.size !== 0)) {
    projectsToDelete.push(existingDefaultProject.id)
    projectsDeleted.push(existingDefaultProject.projectID)
  }

  const projectsToAdd = Array.from(selectedProjects)
  await createVendorProjects(projectsToAdd, clientVendorID)
  await deleteVendorProjects(projectsToDelete)
  return {
    projectsAdded: projectsToAdd,
    projectsDeleted,
  }
}

const updateOneVendor = (state, action) => {
  state.status = 'success'
  state.vendors = state.vendors.map(oneVendor => {
    if(oneVendor.id === action?.payload?.clientVendor?.id) {
      return action.payload.clientVendor
    }
    return oneVendor
  })
}

const statusLoading = state => {
  state.status = 'loading'
}

const statusFailure = state => {
  state.status = 'failure'
}

const actions = {
  fetchVendors: createAsyncThunk("vendor/fetchVendors", async (clientID) => {
    const { data } = await API.graphql({
      query: clientVendorsByClient,
      variables: {
        clientID,
      },
    });
    return {
      vendors: data?.clientVendorsByClient?.items,
    };
  }),
  fetchVendor: createAsyncThunk("vendor/fetchVendor", async (clientVendorID) => {
    const { data } = await API.graphql({
      query: getCLIENTVENDORS,
      variables: {
        id: clientVendorID,
      },
    });
    return {
      success: true,
      clientVendor: data?.getCLIENTVENDORS,
    };
  }),
  updateVendor: createAsyncThunk("vendor/updateVendor", async (vendorData) => {
    const { id, name, useEntityName, entityName, email, additionalEmails, phone, logo, address1, address2, city, state, zip, insuranceAgent } = vendorData;
    let insuranceAgentResponse;
    if (insuranceAgent?.id) {
      await API.graphql({
        query: updateINSURANCEAGENT,
        variables: {
          input: {
            id: insuranceAgent?.id,
            name: insuranceAgent?.name,
            email: insuranceAgent?.email,
            phone: insuranceAgent?.phone,
            address1: insuranceAgent?.address1,
            address2: insuranceAgent?.address2,
            city: insuranceAgent?.city,
            state: insuranceAgent?.state,
            zip: insuranceAgent?.zip,
          },
        },
      });
    } else if (insuranceAgent?.name && insuranceAgent?.email) {
      insuranceAgentResponse = await API.graphql({
        query: createINSURANCEAGENT,
        variables: {
          input: {
            ...insuranceAgent,
          },
        },
      });
    }

    const insuranceAgentID = insuranceAgentResponse?.data.createINSURANCEAGENT?.id;
    await API.graphql({
      query: updateVENDOR,
      variables: {
        input: {
          id,
          name,
          useEntityName,
          entityName,
          email,
          additionalEmails,
          phone,
          logo,
          address1,
          address2,
          city,
          state,
          zip,
          ...(insuranceAgentID && { vENDORInsuranceAgentId: insuranceAgentID }),
        },
      },
    });
    const categoryUpdateResult = await updateVendorCategories(vendorData);
    const projectsUpdateResult = await updateVendorProjects(vendorData);
    const { data } = await API.graphql({
      query: getCLIENTVENDORS,
      variables: {
        id: vendorData.clientVendorID,
      },
    });
    return {
      clientVendor: data?.getCLIENTVENDORS,
      success: true,
      categoryUpdateResult,
      projectsUpdateResult,
    };
  }),
  createVendor: (vendorData) => async (dispatch) => {
    if (vendorData.clientID) {
      const { name, useEntityName, entityName, email, additionalEmails, phone, address1, address2, logo, city, state, zip, defaultProject, insuranceAgent } = vendorData;
      let insuranceAgentResponse;

      if (insuranceAgent?.name && insuranceAgent?.email) {
        insuranceAgentResponse = await API.graphql({
          query: createINSURANCEAGENT,
          variables: {
            input: {
              ...insuranceAgent,
            },
          },
        });
      }
      const insuranceAgentID = insuranceAgentResponse?.data.createINSURANCEAGENT?.id;
      const { data } = await API.graphql({
        query: createVENDOR,
        variables: {
          input: {
            name,
            useEntityName,
            entityName,
            email,
            additionalEmails,
            phone,
            address1,
            address2,
            logo,
            city,
            state,
            zip,
            ...(insuranceAgentID && { vENDORInsuranceAgentId: insuranceAgentID }),
          },
        },
      });

      if (data?.createVENDOR) {
        const clientVendor = await API.graphql({
          query: createCLIENTVENDORS,
          variables: {
            input: {
              clientID: vendorData.clientID,
              vendorID: data.createVENDOR.id,
            },
          },
        });

        if (clientVendor?.data?.createCLIENTVENDORS.id) {
          const otherCategories = new Set(vendorData?.otherCategories);
          if (vendorData?.primaryCategory || otherCategories.size) {
            const categoriesToAdd = [vendorData.primaryCategory, ...Array.from(otherCategories)];
            await createVendorCategories(categoriesToAdd, clientVendor.data.createCLIENTVENDORS.id);
          }

          if (vendorData?.selectedProjects?.length) {
            await createVendorProjects(vendorData.selectedProjects, clientVendor.data.createCLIENTVENDORS.id);
          } else if (defaultProject) {
            await createVendorProjects([defaultProject.id], clientVendor.data.createCLIENTVENDORS.id);
          }
        }

        await dispatch(actions.fetchVendors(vendorData.clientID));
        return {
          payload: {
            success: true,
            vendorId: data.createVENDOR.id,
            clientVendorID: clientVendor?.data?.createCLIENTVENDORS?.id,
          },
        };
      }
    }
  },
  deleteVendor: createAsyncThunk("vendor/deleteVendor", async (clientVendor) => {
    if (clientVendor?.projects?.items.length > 0) {
      const deleteVendorProjectsResponse = await deleteVendorProjects(clientVendor.projects.items.map((project) => project.id));
      const failedProjectDeletion = deleteVendorProjectsResponse.find((response) => !response.success);
      if (failedProjectDeletion) {
        return {
          success: false,
          message: "Unsuccessful vendor deletion from project " + failedProjectDeletion,
          projectId: failedProjectDeletion,
        };
      }
    }

    const deleteVendorResponse = await API.graphql({
      query: deleteVENDOR,
      variables: {
        input: {
          id: clientVendor.vendor.id,
        },
      },
    });

    if (!deleteVendorResponse.data.deleteVENDOR) {
      return {
        success: false,
        message: "Unsuccessful vendor deletion",
      };
    }

    const deleteClientVendorResponse = await API.graphql({
      query: deleteCLIENTVENDORS,
      variables: {
        input: {
          id: clientVendor.id,
        },
      },
    });

    if (!deleteClientVendorResponse.data.deleteCLIENTVENDORS) {
      return {
        success: false,
        message: "Unsuccessful vendor deletion",
      };
    }

    return {
      success: true,
      message: "VENDOR HAS BEEN DELETED",
    };
  }),

  updateStarredVendor: createAsyncThunk("vendor/updateStarredVendor", async ({ id, starred }) => {
    const { data } = await API.graphql({
      query: updateStarredVendor,
      variables: {
        id,
        starred,
      },
    });
    return {
      id,
      starred,
      success: true,
    };
  }),
  updateVendorStatus: createAsyncThunk("vendor/updateVendorStatus", async ({ id, status }) => {
    const { data } = await API.graphql({
      query: updateVendorStatus,
      variables: { id, status },
    });
    return {
      id,
      status,
      success: true,
    };
  }),
  updateVendorNotificationStatus: createAsyncThunk("vendor/updateVendorNotificationStatus", async ({ id, notificationStatus }) => {
    const { data } = await API.graphql({
      query: updateVendorNotificationStatus,
      variables: { id, notificationStatus },
    });
    return {
      id,
      notificationStatus,
      success: true,
    };
  }),
  updateVendorCoverages: createAsyncThunk("vendor/updateVendorCoverages", async ({ clientVendor, selectedCoverages = [], currentCoverages = [] }) => {
    const updateList = [];
    const createList = [];
    // const deleteList = []
    // const existingCoverages = [...currentCoverages]
    selectedCoverages?.map((classCoverage) => {
      const coverageData = {
        ...classCoverage,
        clientVendorID: clientVendor.id,
      };
      if (typeof coverageData.limits === "object") {
        coverageData.limits = JSON.stringify(coverageData.limits);
      }
      delete coverageData.createdAt;
      delete coverageData.updatedAt;
      delete coverageData.index;
      (classCoverage.id ? updateList : createList).push(coverageData);
    });

    // Object.entries(existingCoverages).map(([classificationID, classCoverage]) => {
    //   classCoverage && Object.entries(classCoverage).map(([coverageID, amount]) => {
    //     const vendorCoverageEntries = clientVendor?.vendorCoverages.items?.filter(vendorCoverage => (
    //       vendorCoverage.categoryID === classificationID && vendorCoverage.clientCoverageID === coverageID
    //     ))
    //     vendorCoverageEntries?.forEach(vendorCoverageEntry => {
    //       if(vendorCoverageEntry.id) {
    //         deleteList.push({
    //           id: vendorCoverageEntry.id
    //         })
    //       }
    //     })
    //
    //   })
    // })
    await Promise.all(
      updateList.map(async (toUpdate) => {
        await API.graphql({
          query: updateVENDORCOVERAGE,
          variables: {
            input: {
              ...toUpdate,
            },
          },
        });
        return Promise.resolve();
      })
    );
    await Promise.all(
      createList.map(async (toCreate) => {
        await API.graphql({
          query: createVENDORCOVERAGE,
          variables: {
            input: {
              ...toCreate,
            },
          },
        });
        return Promise.resolve();
      })
    );
    // await Promise.all(deleteList.map(async toDelete => {
    //   await API.graphql({
    //     query: deleteVENDORCOVERAGE,
    //     variables: {
    //       input: {
    //         ...toDelete
    //       }
    //     }
    //   })
    //   return Promise.resolve()
    // }))
    const { data } = await API.graphql({
      query: getCLIENTVENDORS,
      variables: {
        id: clientVendor.id,
      },
    });
    return {
      success: true,
      clientVendor: data?.getCLIENTVENDORS,
    };
  }),

  submitScanResultToCertificate:
    ({ certificateData, scanData }) =>
    async (dispatch) => {
      if (certificateData?.id && scanData?.id && certificateData.id === scanData.certificateID) {
        const currentCoverages = certificateData?.coverages?.items;
        const newCoverages = scanData?.coverages?.items;
        // move acord25 file over
        await copyScannedAcord25(certificateData.id, scanData.id, scanData.acord25File);
        // save the new certificate data
        const updates = {
          ...scanData,
          insured: scanData.insured || " ",
          producer: scanData.producer || " ",
          status: "REVIEW",
        };
        delete updates.id;
        delete updates.coverages;
        delete updates.certificateID;
        delete updates.scanError;
        delete updates.scanStage;
        delete updates.files;
        delete updates.createdAt;
        delete updates.updatedAt;

        // save new coverages
        if (newCoverages?.length) {
          updates.firstExp = getFirstExp(newCoverages);
          await Promise.all(
            newCoverages.map((newCoverage) => {
              delete newCoverage.id;
              delete newCoverage.acord25ScanID;
              return API.graphql({
                query: createCERTIFICATECOVERAGE,
                variables: {
                  input: {
                    ...newCoverage,
                    certificateID: certificateData.id,
                  },
                },
              });
            })
          );
        }

        // delete original coverages
        if (currentCoverages?.length) {
          await Promise.all(
            currentCoverages.map((currentCoverage) => {
              return API.graphql({
                query: deleteCERTIFICATECOVERAGE,
                variables: {
                  input: {
                    id: currentCoverage.id,
                  },
                },
              });
            })
          );
        }

        // save the rest of the cert data
        await API.graphql({
          query: updateCERTIFICATE,
          variables: {
            input: {
              ...updates,
              id: certificateData.id,
            },
          },
        });

        // update scan result to submitted
        API.graphql({
          query: updateACORD25SCAN,
          variables: {
            input: {
              id: scanData.id,
              status: "SUBMITTED",
            },
          },
        });

        // refresh certificate data
        await dispatch(actions.getCertificate(certificateData.id));

        return {
          payload: {
            success: true,
            certificateID: certificateData.id,
          },
        };
      }
    },
  createOrUpdateCertificate: createAsyncThunk(
    "vendor/createOrUpdateCertificate",
    async ({ certificateData, updatedFiles, updatedSupplementalCOIDocumentation = {}, coveragesToDelete = [] }, { dispatch }) => {
      const data = {
        ...certificateData,
        insured: certificateData.insured || " ",
        producer: certificateData.producer || " ",
        ...(!certificateData.id && { acord25FileDate: usToAWSDate(new Date().toLocaleDateString("en-US")) }),
        ...(certificateData.overrides && {
          overrides: typeof certificateData.overrides === "string" ? certificateData.overrides : JSON.stringify(certificateData.overrides),
        }),
      };
      const coverages = data?.coverages?.items;
      let supplementalCoiFileUploadsAndDeletions;
      delete data.createdAt;
      delete data.updatedAt;
      if (coverages) {
        delete data.coverages;
      }
      if (updatedFiles?.length) {
        const files = updatedFiles
          ?.filter((updatedFile) => !updatedFile.isDeleted && !updatedFile.isAcord25 && updatedFile.name !== data.acord25File)
          ?.map((updatedFile) => updatedFile.name);
        data.files = files?.length ? files : null;
      }

      if (Object.keys(updatedSupplementalCOIDocumentation).length) {
        supplementalCoiFileUploadsAndDeletions = {
          upload: {},
          delete: {},
        };
        let newSupplementalCoiDocuments = {};
        
        if ( data.supplementalCoiFiles && typeof data.supplementalCoiFiles === "string") {
          data.supplementalCoiFiles = JSON.parse(data.supplementalCoiFiles);
        }

          Object.entries(updatedSupplementalCOIDocumentation).forEach(([insuranceType, documents]) =>
            Object.entries(documents).forEach(([documentType, file]) => {
              if (file.isNew) {
                // Ensure that there is an object property "insuranceType" in the object
                newSupplementalCoiDocuments[insuranceType] = newSupplementalCoiDocuments[insuranceType] || {};
                if (data.supplementalCoiFiles && data.supplementalCoiFiles[insuranceType] && data.supplementalCoiFiles[insuranceType][documentType]) {
                  // Ensure that there is an object property "insuranceType" in the object
                  supplementalCoiFileUploadsAndDeletions["delete"][insuranceType] = supplementalCoiFileUploadsAndDeletions["delete"][insuranceType] || {};
                  supplementalCoiFileUploadsAndDeletions["delete"][insuranceType][documentType] = data.supplementalCoiFiles[insuranceType][documentType];
                }
                newSupplementalCoiDocuments[insuranceType][documentType] = file.name;
                // Ensure that there is an object property "insuranceType" in the object
                supplementalCoiFileUploadsAndDeletions["upload"][insuranceType] = supplementalCoiFileUploadsAndDeletions["upload"][insuranceType] || {};
                supplementalCoiFileUploadsAndDeletions["upload"][insuranceType][documentType] = file;
              } else {
                // Ensure that there is an object property "insuranceType" in the object
                newSupplementalCoiDocuments[insuranceType] = newSupplementalCoiDocuments[insuranceType] || {};
                newSupplementalCoiDocuments[insuranceType][documentType] = file.name;
              }
            })
          );
        data.supplementalCoiFiles = JSON.stringify(newSupplementalCoiDocuments);
      }

      if (coverages?.length) {
        data.firstExp = getFirstExp(coverages);
      }

      const certificate = await API.graphql({
        query: data.id ? updateCERTIFICATE : createCERTIFICATE,
        variables: {
          input: data,
        },
      });
      const certificateID = data.id || certificate?.data?.createCERTIFICATE?.id;
      if (coverages?.length) {
        await Promise.all(
          coverages.map(async (coverage) => {
            delete coverage.index;
            const limits = coverage?.limits && typeof coverage.limits !== "string" ? JSON.stringify(coverage.limits) : coverage?.limits;
            const overrides = coverage?.overrides && typeof coverage.overrides !== "string" ? JSON.stringify(coverage.overrides) : coverage?.overrides;

            return await API.graphql({
              query: coverage.id ? updateCERTIFICATECOVERAGE : createCERTIFICATECOVERAGE,
              variables: {
                input: {
                  ...coverage,
                  certificateID,
                  limits,
                  overrides,
                },
              },
            });
          })
        );
      }
      if (coveragesToDelete?.length) {
        await Promise.all(
          coveragesToDelete.map((coverage) =>
            API.graphql({
              query: deleteCERTIFICATECOVERAGE,
              variables: {
                input: {
                  id: coverage.id,
                },
              },
            })
          )
        );
      }
      if (updatedFiles?.length) {
        await Promise.all(
          updatedFiles.map(async (updatedFile) => {
            if (updatedFile.isNew) {
              const identityId = updatedFile.isAcord25 ? "scanning" : "certificates";
              let scanData;
              if (updatedFile.isAcord25) {
                scanData = await API.graphql({
                  query: createACORD25SCAN,
                  variables: {
                    input: {
                      status: "PENDING",
                      certificateID,
                      acord25File: updatedFile.name,
                    },
                  },
                });
              }
              return uploadCertificateFile(certificateID, identityId, updatedFile.name, updatedFile.content, scanData?.data?.createACORD25SCAN?.id);
            } else if (updatedFile.isDeleted) {
              const filePath = updatedFile.isAcord25 ? `acord25/${updatedFile.name}` : updatedFile.name;
              return deleteCertificateFile(certificateID, filePath);
            }
            return Promise.resolve();
          })
        );
      }
      if (supplementalCoiFileUploadsAndDeletions) {
        await Promise.all(
          Object.keys(supplementalCoiFileUploadsAndDeletions.delete).flatMap(async (insuranceType) => 
            Object.entries(supplementalCoiFileUploadsAndDeletions["delete"][insuranceType]).map(async ([documentType, file]) => {
              console.log('deleting', insuranceType, documentType, file)
              return deleteCertificateSupplementalCoiFile(certificateID, insuranceType, documentType, file);
            })
          )
        );
        await Promise.all(
          Object.keys(supplementalCoiFileUploadsAndDeletions.upload).flatMap(async (insuranceType) => 
            Object.entries(supplementalCoiFileUploadsAndDeletions["upload"][insuranceType]).map(async ([documentType, file]) => {
              return uploadCertificateSupplementalCoiFile(certificateID, insuranceType, documentType, file.name, file.content);
            })
          )
        );
      }
      await dispatch(actions.getCertificate(certificateID));
      return {
        success: true,
        certificateID,
      };
    }
  ),
  deleteCertificate: (certificate) => async (dispatch) => {
    const { coverages, id: certificateID } = certificate;

    if (coverages?.items.length) {
      await Promise.all(
        coverages?.items.map((coverage) =>
          API.graphql({
            query: deleteCERTIFICATECOVERAGE,
            variables: {
              input: {
                id: coverage.id,
              },
            },
          })
        )
      );
    }

    await API.graphql({
      query: deleteCERTIFICATE,
      variables: {
        input: {
          id: certificateID,
        },
      },
    });
    await deleteCertificateFromS3(certificateID);
    return {
      payload: {
        success: true,
        certificateID,
      },
    };
  },
  getCertificate: createAsyncThunk("vendor/getCertificate", async (certificateId) => {
    const { data } = await API.graphql({
      query: getCERTIFICATE,
      variables: {
        id: certificateId,
      },
    });
    return {
      success: true,
      certificate: data?.getCERTIFICATE,
    };
  }),
  sendEmailToVendor: createAsyncThunk("vendor/sendEmailToVendor", async ({ uploadLink, ...rest }) => {
    const { data } = await API.graphql({
      query: createEMAILNOTIFICATIONS,
      variables: {
        input: {
          ...rest,
          ...(uploadLink ? { uploadLink } : {}),
        },
      },
    });
    if (data?.createEMAILNOTIFICATIONS) {
      return {
        success: true,
      };
    }
  }),
  createRequestCertificate: createAsyncThunk(
    "vendor/createRequestCertificate",
    async ({ clientVendorID, projectID, clientID, initialCoverageRequirements, sentBy, sentTo }) => {
      console.log("createRequestCertificate", sentBy, sentTo);
      const result = await API.graphql({
        query: createCERTIFICATE,
        variables: {
          input: {
            clientID,
            clientVendorID,
            status: "REQUESTED",
            initialCoverageRequirements,
            ...(projectID ? { projectID } : {}),
            ...(sentBy ? { sentBy } : {}),
            ...(sentTo ? { sentTo } : {}),
          },
        },
      });
      return {
        success: true,
        certificateId: result?.data?.createCERTIFICATE?.id,
      };
    }
  ),
  getRequestCertificates: createAsyncThunk("vendor/getRequestCertificates", async ({ clientVendorID, projectID, clientID }) => {
    const existing = await API.graphql({
      query: certificateByClientVendor,
      variables: {
        clientVendorID,
        filter: {
          projectID: { ...(projectID ? { eq: projectID } : { attributeExists: false }) },
          status: { eq: "REQUESTED" },
        },
      },
    });
    return {
      success: true,
      certificateId: existing.data.certificateByClientVendor?.items,
    };
  }),
  getAllVendorClientCertificates: createAsyncThunk("vendor/getAllVendorClientCertificates", async ({ clientID, clientVendorID }) => {
    let nextToken = null;
    let runningCount = 0;
    let initialRun = true;
    let certificates = [];
    do {
      const { data } = await API.graphql({
        query: certificateByClient,
        variables: {
          clientID,
          nextToken,
          filter: {
            // status: { ne: "REQUESTED" },
            clientVendorID: { eq: clientVendorID },
          },
        },
      });
      initialRun = false;
      if (data?.certificateByClient?.items?.length) {
        runningCount += data.certificateByClient.items.length;
        certificates = certificates.concat(data.certificateByClient.items);
      }
      nextToken = data?.certificateByClient?.nextToken || null;
    } while (initialRun || nextToken);

    certificates.sort((a, b) => {
      let larger;
      if (a.updatedAt && b.updatedAt) {
        larger = new Date(a.updatedAt).getTime() > new Date(b.updatedAt).getTime();
      }
      if (larger) {
        return -1;
      }
      return 1;
    });

    return {
      success: true,
      certificatesCount: runningCount,
      certificates,
    };
  }),
};

export const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    resetState: (state, action) => {
      state.vendors = null
      state.certificates = {}
      state.vendorCertificates = null;
      state.paginatedVendorCertificates = null;
      state.vendorCertificatesCount = 0;
    },
  },
  extraReducers: builder => {
    builder
      // fetching vendors
      .addCase(actions.fetchVendors.pending, statusLoading)
      .addCase(actions.fetchVendors.fulfilled, (state, action) => {
        state.status = 'success'
        state.vendors = action.payload.vendors
      })
      .addCase(actions.fetchVendors.rejected, statusFailure)
      // fetching vendor
      .addCase(actions.fetchVendor.pending, statusLoading)
      .addCase(actions.fetchVendor.fulfilled, updateOneVendor)
      .addCase(actions.fetchVendor.rejected, statusFailure)
      // update vendor
      .addCase(actions.updateVendor.pending, statusLoading)
      .addCase(actions.updateVendor.fulfilled, updateOneVendor)
      .addCase(actions.updateVendor.rejected, statusFailure)
      // update starred vendor
      .addCase(actions.updateStarredVendor.pending, statusLoading)
      .addCase(actions.updateStarredVendor.fulfilled, (state, action) => {
        state.status = 'success'
        state.vendors = state.vendors.map(oneVendor => {
          if(oneVendor.id === action?.payload?.id) {
            oneVendor.starred = action.payload.starred
          }
          return oneVendor
        })
      })
      .addCase(actions.updateStarredVendor.rejected, statusFailure)
      // update vendor status
      .addCase(actions.updateVendorStatus.pending, statusLoading)
      .addCase(actions.updateVendorStatus.fulfilled, (state, action) => {
        state.status = 'success'
        state.vendors = state.vendors.map(oneVendor => {
          if(oneVendor.id === action?.payload?.id) {
            oneVendor.status = action.payload.status
          }
          return oneVendor
        })
      })
      .addCase(actions.updateVendorStatus.rejected, statusFailure)
      // update vendor nofitication status
      .addCase(actions.updateVendorNotificationStatus.pending, statusLoading)
      .addCase(actions.updateVendorNotificationStatus.fulfilled, (state, action) => {
        state.status = 'success'
        state.vendors = state.vendors.map(oneVendor => {
          if (oneVendor.id === action?.payload?.id) {
            oneVendor.notificationStatus = action.payload.notificationStatus
          }
          return oneVendor
        })
      })
      .addCase(actions.updateVendorNotificationStatus.rejected, statusFailure)
      //update Vendor Coverages
      .addCase(actions.updateVendorCoverages.pending, statusLoading)
      .addCase(actions.updateVendorCoverages.fulfilled, updateOneVendor)
      .addCase(actions.updateVendorCoverages.rejected, statusFailure)
      //get certificate
      .addCase(actions.getCertificate.pending, statusLoading)
      .addCase(actions.getCertificate.fulfilled, (state, action) => {
        state.certificates = {
          ...state.certificates,
          [action.payload?.certificate?.id]: action.payload?.certificate
        }
        const updatedCertificateIndex = state.vendorCertificates?.findIndex(cert => cert.id === action.payload?.certificate?.id)

        if (updatedCertificateIndex > -1) {
          state.vendorCertificates[updatedCertificateIndex] = action.payload?.certificate
        }
      })
      .addCase(actions.getCertificate.rejected, statusFailure)
      //update certificate
      .addCase(actions.createOrUpdateCertificate.pending, statusLoading)
      .addCase(actions.createOrUpdateCertificate.fulfilled, (state, action) => {
        state.status = "success"
        //refresh vendor certificate's on updated or created certificate
        state.vendorCertificates = [...state.vendorCertificates || []]
      })
      // .addCase(actions.createOrUpdateCertificate.rejected, statusFailure)
      // get all vendor certificates
      .addCase(actions.getAllVendorClientCertificates.fulfilled, (state, action) => {
        state.vendorCertificates = action.payload.certificates;
        state.vendorCertificatesCount = action.payload.certificatesCount;
      })
  }
})

export const vendorState = state => state.vendor
export const vendorCertificatesState = state => ({
  vendorCertificates: state.vendor.vendorCertificates,
  vendorCertificatesCount: state.vendor.vendorCertificatesCount
})
export const certificateState = (certificateId) => state => state.vendor.certificates[certificateId]
export const vendorActions = {
  ...vendorSlice.actions,
  ...actions
}

export default vendorSlice.reducer
