import React, { useEffect, useState } from "react";
import { Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack, IconButton, Link, Tooltip, Typography } from "@mui/material";
import { Tab, Menu } from "semantic-ui-react";
import { non_custom_insurance_types, supplementalCOIDocumentation } from "constants/insurance_types";
import SupplementalCOIDocumentationErrorIndicatorHeader from "./SupplementalCOIDocumentationErrorIndicatorHeader";
import FileUploadDragAndDrop from "components/shared/FileUploadDragAndDrop";
import AddIcon from "@mui/icons-material/Add";
import RestorePageOutlinedIcon from "@mui/icons-material/RestorePageOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function UploadRequestedCOISupplementalDocumentation({
  fileSelected,
  certificateFiles,
  requestedSupplementalDocumentation = {},
  uploadedSupplementalCOIDocumentation = {},
  onError = () => {},
  handleFileUpload = () => {},
  setFileSelected = () => {},
  ...props
}) {
  const [supplementalFiles, setSupplementalFiles] = useState({ ...uploadedSupplementalCOIDocumentation });

  useEffect(() => {
    const totalErrors = Object.keys(requestedSupplementalDocumentation).reduce((sum, insuranceType) => sum + getUploadErrors(insuranceType), 0);

    onError(totalErrors);
  }, [supplementalFiles, requestedSupplementalDocumentation]);

  const onFileUpload = (event, insuranceType, documentType) => {
    const file = event.target?.files?.[0] || event?.dataTransfer?.files?.[0];
    if (file) {
      setSupplementalFiles((prev) => ({
        ...prev,
        [insuranceType]: {
          ...(prev[insuranceType] || {}),
          [documentType]: file,
        },
      }));

      handleFileUpload(event, true, {
        isSupplementalCoiFile: true,
        insuranceType,
        documentType,
      });
    }
  };

  const getUploadErrors = (insuranceType) => {
    const requiredDocs = requestedSupplementalDocumentation[insuranceType] || [];
    const uploadedFiles = supplementalFiles[insuranceType] || {};
    return requiredDocs.length - Object.keys(uploadedFiles).length;
  };

  const FileLineItem = ({ file, handleFileDelete, fileSelected, setFileSelected }) => (
    <Stack sx={{ mt: 2 }} direction="row" alignItems={"center"}>
      {handleFileDelete && (
        <IconButton onClick={() => handleFileDelete(file.name)} sx={{ mr: 1 }}>
          {file.isDeleted ? <RestorePageOutlinedIcon /> : <DeleteForeverIcon />}
        </IconButton>
      )}

      <Link
        component={"button"}
        onClick={() =>
          setFileSelected({
            name: file.name,
            insuranceType: file.insuranceType,
            documentType: file.documentType,
            isSupplementalCoiFile: true,
          })
        }
        color={fileSelected?.name === file.name && !file.isDeleted ? "primary" : "inherit"}
        sx={{ textDecoration: file.isDeleted ? "line-through" : "underline", ml: handleFileDelete ? 0 : 2 }}
      >
        {file.name}
      </Link>
      {file.isNew && (
        <Box sx={{ ml: 1 }}>
          <Tooltip title={"Your files are not saved until you click Save"}>
            <Typography variant="caption"> - new</Typography>
          </Tooltip>
        </Box>
      )}
    </Stack>
  );

  const SupplementalDocumentationTypeSection = ({ insuranceType, coverageRequirement }) => (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Document Type</TableCell>
              <TableCell>File</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coverageRequirement?.map((doc, index) => {
              const hasFile = supplementalFiles[insuranceType]?.[doc];
              return (
                <TableRow key={`${insuranceType}-${doc}-${index}`}>
                  <TableCell component="th" scope="row">
                    {doc}
                  </TableCell>

                  <TableCell>
                    {supplementalFiles[insuranceType]?.[doc]?.name ? (
                      <FileLineItem
                        file={{
                          name: supplementalFiles[insuranceType]?.[doc]?.name,
                          isNew: uploadedSupplementalCOIDocumentation[insuranceType]?.[doc]?.isNew,
                          insuranceType: insuranceType,
                          documentType: doc,
                        }}
                        fileSelected={fileSelected}
                        setFileSelected={setFileSelected}
                      />
                    ) : (
                      "No file uploaded"
                    )}
                  </TableCell>
                  <TableCell align="right" sx={{ padding: "0 !important" }}>
                    <FileUploadDragAndDrop
                      buttonText={hasFile ? "REPLACE" : "SELECT OR DROP FILE"}
                      buttonProps={{
                        startIcon: <AddIcon />,
                        disabled: false,
                      }}
                      onChange={(e) => onFileUpload(e, insuranceType, doc)}
                      sx={{ minWidth: "200px", border: "none" }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const getPanes = () => {
    // Get insurance types that have requirements
    const insuranceTypesWithRequirements = Object.keys(requestedSupplementalDocumentation).filter((insuranceType) => {
      const requirements = requestedSupplementalDocumentation[insuranceType];
      const isCustomType = !non_custom_insurance_types.includes(insuranceType);

      // Include if:
      // 1. Has requirements AND (is standard type OR is custom type)
      return Array.isArray(requirements) && requirements.length > 0 && (non_custom_insurance_types.includes(insuranceType) || isCustomType);
    });

    let panes = Array(insuranceTypesWithRequirements.length).fill({});

    insuranceTypesWithRequirements.forEach((insuranceType, tabIndex) => {
      const index = non_custom_insurance_types.indexOf(insuranceType);
      const uploadErrors = getUploadErrors(insuranceType);

      const pane = {
        menuItem: (
          <Menu.Item key={tabIndex}>
            <SupplementalCOIDocumentationErrorIndicatorHeader
              text={insuranceType}
              coverageErrors={uploadErrors}
              tooltipText={uploadErrors > 0 ? `${uploadErrors} file${uploadErrors > 1 ? "s" : ""} pending upload` : "All files uploaded"}
            />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane style={{ border: "none", display: "flex", flexDirection: "column", paddingLeft: 0, paddingRight: 0 }} key={tabIndex}>
            <SupplementalDocumentationTypeSection coverageRequirement={requestedSupplementalDocumentation[insuranceType]} insuranceType={insuranceType} />
          </Tab.Pane>
        ),
      };
      if (index === -1) {
        panes.push(pane);
      } else {
        panes[index] = pane;
      }
    });

    return panes.filter(Boolean); // Remove any empty slots
  };

  return (
    <Box component="div" {...props}>
      <Grid sx={{ my: 3 }} container spacing={0}>
        <Tab panes={getPanes()} menu={{ secondary: true, pointing: true }} style={{ width: "100%" }} />
      </Grid>
    </Box>
  );
}
