import React, {useState, useMemo, useEffect} from "react";
import { useParams } from "react-router-dom";
import {Box, Button, Stack, Typography, Divider, RadioGroup, Radio, InputAdornment, IconButton, TextField} from "@mui/material"
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {Tab, Menu, Popup} from "semantic-ui-react";
import {useSelector, useDispatch} from "react-redux";
import {clientClassificationsState} from "redux/clientSlice";
import {vendorActions} from "redux/vendorSlice";
import {client_mode} from "constants/client_properties";
import insurance_types, {non_custom_insurance_types} from "constants/insurance_types";
import CoverageTypeSection from "components/shared/CoverageForm/CoverageTypeSection";
import Modal from "components/shared/Modal"
import {SubtitleInline} from "components/shared/Typography";
import ProjectLineItem from "components/VendorDetails/ProjectLineItem";
import LoaderButton from "components/shared/LoaderButton";
import Snackbar from "../shared/Snackbar";

export default function CertificateRequirementSection({
  vendor,
  clientMode,
  vendorClassifications=[],
  clientCoverages,
  projectID,
  certificateID,
  clientVendorID,
  onCreateUpdatePathWithId,
  addCertificateProjectActivity,
  hasEditPrivileges,
  handleCertificateSubmit,
  projectLabel,
  vendorLabel
}) {
  const { clientId, certificateId } = useParams()
  const isCreate = certificateId === 'create';
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(undefined)
  const clientClassifications = useSelector(clientClassificationsState)
  const [selectedProjectID, setSelectedProjectID] = useState(null)
  const [projectSaving, setProjectSaving] = useState(false)
  const [message, setMessage] = useState()
  useEffect(() => {
    if(projectID) {
      setSelectedProjectID(projectID)
    }
  }, [projectID])

  const vendorProjects = useMemo(() => {
    if(clientMode === client_mode.project && clientClassifications?.length) {
      return vendorClassifications?.map(vendorClassification => {
        return clientClassifications.find(
          clientClassification => (vendorClassification.projectID === clientClassification.id)
        )
      })
    }
  }, [clientMode, vendorClassifications, clientClassifications])

  const [coverages, coverageType, vendorClassification, defaultProject] = useMemo(() => {
    let references = [clientCoverages, "client"]
    if(clientClassifications) {
      let vendorClassification;
      let referenceID;
      if(client_mode.project === clientMode && projectID) {
        referenceID = projectID
      } else if(client_mode.category === clientMode){
        referenceID = vendorClassifications.find(category => category.primary)?.categoryID
      }
      const defaultProject = clientClassifications.find(clientClassification => clientClassification.isDefault)
      vendorClassification = clientClassifications.find(clientClassification => clientClassification.id === referenceID) || defaultProject;
      return [
        typeof vendorClassification?.requiredCoverages === "string" ? JSON.parse(vendorClassification.requiredCoverages) : (vendorClassification?.requiredCoverages || []),
        clientMode,
        vendorClassification,
        defaultProject,
      ]
    }
    return references
  }, [clientCoverages, clientClassifications?.length, projectID, clientMode])

  const [selectProjectOpen, setSelectProjectOpen] = useState(false)
  function displayProjectSelect() {
    setSelectProjectOpen(true)
  }
  function cancelProjectSelect() {
    setSelectProjectOpen(false)
    setSelectedProjectID(projectID)
  }

  async function onSaveCertificateProject() {
    setProjectSaving(true)
    const result = await handleCertificateSubmit({projectID: selectedProjectID})
    if(result?.success) {
      addCertificateProjectActivity(
        vendorProjects.find(project => project.id === projectID)?.name,
        vendorProjects.find(project => project.id === selectedProjectID)?.name
      )
      setSelectProjectOpen(false)
      if(!certificateID) {
        onCreateUpdatePathWithId && onCreateUpdatePathWithId(result?.payload?.certificateID)
      }
    }
    setProjectSaving(false)
  }

  function getPanes() {
    let panes = Array(non_custom_insurance_types.length).fill({});
    coverages.forEach((coverageRequirement, tabIndex) => {
      const {insuranceType, index: coverageIndex} = coverageRequirement
      const index = non_custom_insurance_types.indexOf(insuranceType)

      const pane = {
        menuItem: (
          <Menu.Item key={tabIndex}>
            {(index === -1 && coverageRequirement.name) ? String(coverageRequirement.name).substring(0,3).toUpperCase() : coverageRequirement.insuranceType}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane style={{border: "none"}} key={tabIndex}>
            <CoverageTypeSection
              index={coverageIndex}
              isEdit={false}
              isCustom={insuranceType === "CUSTOM"}
              coverageTypeConfig={insurance_types[insuranceType]}
              coverageRequirement={coverageRequirement}
              clientMode={clientMode}
              showHelperText={false}
            />
          </Tab.Pane>
        )
      }
      if(index === -1) {
        panes.push(pane)
      } else {
        panes[index] = pane
      }
    })
    return panes
  }

  return (
    <>
      {!isCreate && (
        <Box sx={{ mt: 2 }}>
          <SubtitleInline>{`${vendorLabel} UPLOAD LINK`.toUpperCase()}</SubtitleInline>
          <Popup
            position="bottom center"
            trigger={
              <TextField
                fullWidth
                inputProps={{
                  readOnly: true,
                  style: { cursor: 'pointer' }
                }}
                value={window.location.origin + `/vendor/upload/${clientId}/${vendor?.id}/${certificateId}`}
                variant="outlined"
                onClick={(event) => window.navigator.clipboard.writeText(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton 
                        onClick={(e) => {
                          e.stopPropagation();
                          const url = window.location.origin + `/vendor/upload/${clientId}/${vendor?.id}/${certificateId}`;
                          window.open(url, '_blank');
                        }} 
                        edge="end"
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            }
          >
            Click to Copy URL
          </Popup>
          <Divider sx={{ mt: 2, mb: 1 }} />
        </Box>
      )}
      {
        !!isCreate &&
        <Typography variant="subtitle2" sx={{mt: 2}}>
          Please click the Save button to create this certificate.
          <br />
          The vendor upload link will only be available after this certificate is created.
        </Typography>
      }
      
      {
        clientMode === client_mode.project &&
        <>
          <Snackbar message={message} setMessage={setMessage} />
          <Modal
          title={`Select ${projectLabel} For Certificate`}
          open={selectProjectOpen}
          onClose={cancelProjectSelect}
          content={
            !!vendorProjects?.length &&
              <>
                <Divider/>
                <Stack spacing={1} ml={2} mr={2} mt={2}>
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    <Box sx={{width: "38px"}}></Box>
                    <SubtitleInline secondary={true}>{projectLabel} NAME</SubtitleInline>
                    <SubtitleInline secondary={true} sx={{ml: "auto", width: "141px"}}>DURATION</SubtitleInline>
                    <SubtitleInline secondary={true} sx={{ml: 2, textAlign: "center"}}>STATUS</SubtitleInline>
                  </Box>
                  <RadioGroup onChange={event => setSelectedProjectID(event.target.value)} value={selectedProjectID}>
                    {
                      [...vendorProjects].map((project, index) => (
                        <ProjectLineItem key={"project"+index} project={project} selected={projectID} selectable={true}/>
                      ))
                    }
                  </RadioGroup>
                </Stack>
              </>
            }
            actions={
              <>
                <Button onClick={cancelProjectSelect} variant="outlined">Cancel</Button>
                <LoaderButton
                  disabled={projectID === selectedProjectID}
                  variant="contained"
                  onClick={onSaveCertificateProject}
                  loading={projectSaving}
                >
                  Save
                </LoaderButton>
              </>
            }
          />
        </>
      }
      <Box>
        {
          coverageType === client_mode.project &&
          <Box>
            <Typography variant={"subtitle1"} color={"text.secondary"}>{projectLabel} Requirements</Typography>
            <Stack direction="row" sx={{alignItems: "center"}}>
              <Typography variant={"h6"}>{vendorClassification?.name}</Typography>
              <Button disabled={!hasEditPrivileges} sx={{ml: "auto"}} onClick={displayProjectSelect}>CHANGE {projectLabel}</Button>
            </Stack>
          </Box>
        }
        {
          coverageType === client_mode.category &&
          <Box>
            <Typography variant={"subtitle1"} color={"text.secondary"}>Primary Category Requirement</Typography>
            <Stack direction="row" sx={{alignItems: "center"}}>
              <Typography variant={"h6"}>{vendorClassification?.name}</Typography>
            </Stack>
          </Box>
        }
        {
          coverageType === 'client' &&
          <Box>
            {
              clientMode === client_mode.project &&
              <>
                <Box><Typography variant={"subtitle1"} color={"text.secondary"}>{projectLabel} Requirements</Typography></Box>
                <Box mb={2}>
                  {
                    !vendorClassifications?.length
                      ? "This subcontractor is not assigned to any project"
                      : <>No {projectLabel} associated. <Button onClick={displayProjectSelect}>Set {projectLabel}</Button></>
                  }
                </Box>
                {/*<Divider/>*/}
              </>
            }
            {/*<Box mt={2}><Typography variant={"subtitle1"} color={"text.secondary"}>Client Default Requirement</Typography></Box>*/}
          </Box>
        }
        {
          !coverages?.length &&
          `No requirements specified for this ${projectLabel}.`
        }
        {
          !!coverages?.length &&
          <Tab
            panes={getPanes()}
            activeIndex={activeIndex}
            onTabChange={()=>setActiveIndex(undefined)}
            menu={{ secondary: true, pointing: true }}
          />
        }
      </Box>
    </>
  )
}
